
import Vue from 'vue';
import credits from '../../themes/credits_stack';

export default Vue.extend({
  computed: {
    pluginName(): string {
      return credits.pluginName;
    },
    pluginUrl(): string | undefined {
      return this.$store.getters['wsStore/pluginUrl']();
    },
    isPluginExpired(): boolean {
      return this.$store.getters['wsStore/isExpired']();
    },
    getCurrentVersion(): boolean {
      return this.$store.getters['wsStore/getCurrentVersion']();
    },
    getStoreVersion(): boolean {
      return this.$store.getters['wsStore/getStoreVersion']();
    },
  },
});
